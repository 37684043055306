// Here you can add other styles
@font-face {
	font-family: 'Avenir-Light';
	src: local('Avenir-Light'), url('../fonts/Avenir-Light.ttf') format('truetype');
}

html, body, input, button {
    font-family: 'Avenir-Light' !important;
}

.c-sidebar {
	background: #23497F;
}

.btn-primary {
	background-color: #23497F;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
	background-color: rgba(255, 255, 255, 0.05);
}

.c-sidebar .c-sidebar-brand {
	background: #fff;
}

.page-item.active .page-link {
	background-color: #23497F;
}

.c-switch-primary .c-switch-input:checked + .c-switch-slider {
	background-color: #23497F;
}

.c-avatar-img {
	border-radius: 0;
	height: 20px;
    width: 20px;
    margin-right: -25px;
}

.breadcrumb-item a {
	color: #23497F;
}

.page-link {
	color: #23497F;
}

.forgot-password-span {
	cursor: pointer;
}

#employees-list .c-datatable-filter .form-control {
	width: 400px;
}

#admins-list .c-datatable-filter .form-control {
	width: 300px;
}

.c-sidebar-brand-full {
	padding-top: 5px;
	padding-bottom: 5px;
}
